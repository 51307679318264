import React from 'react';
import './App.css';
import { FaMobileAlt, FaUsers, FaChartLine } from 'react-icons/fa';

function App() {
  return (
      <div className="App">
        {/* Header Section */}
        <header className="header" id="top">
          <div className="logo">pinglo</div>
          <nav className="nav">
            <a href="#top">Home</a>
            <a href="#top">About</a>
            <a href="#top">Contact</a>
          </nav>
        </header>

        {/* Hero Section */}
        <section className="hero">
          <div className="hero-content">
            <div className="hero-text">
              <h1>Helping small businesses connect with their users</h1>
              <p>Your bridge to seamless customer engagement.</p>
            </div>
            <button className="cta-button">Get Started</button>
          </div>
        </section>

        {/* Features Section */}
        <section className="features">
          <h2>Features</h2>
          <div className="features-container">
            <div className="feature">
              <FaMobileAlt size={50} />
              <h3>Mobile Connectivity</h3>
              <p>Engage customers on the go with our mobile-first platform.</p>
            </div>
            <div className="feature">
              <FaUsers size={50} />
              <h3>User Engagement</h3>
              <p>Boost interaction with personalized experiences.</p>
            </div>
            <div className="feature">
              <FaChartLine size={50} />
              <h3>Whatsapp</h3>
              <p>Get closer to your customers</p>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>What Our Users Say</h2>
          <div className="testimonials-container">
            <div className="testimonial">
              <p>
                "Pinglo has transformed how we connect with our customers. It's intuitive and highly effective."
              </p>
              <h4>- Fabio Salamanca, Founder of Odontofamilia</h4>
            </div>
          </div>
        </section>

        {/* Footer Section */}
        <footer className="footer">
          <p>&copy; {new Date().getFullYear()} pinglo. All rights reserved.</p>
        </footer>
      </div>
  );
}

export default App;
